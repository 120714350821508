<route>
{
  "meta": {
    "auth": "orderManage"

  }
}
</route>

<template>
  <div>
    <el-card class="searchContainer">
      <div class="desc">筛选查询</div>
      <el-row :gutter="20" class="query">
        <el-col :span="5">
          <span class="searchText">ID</span>
          <el-input
            class="searchInput"
            oninput="value=value.replace(/[^0-9]/g,'')"
            placeholder="请输入用户ID/订单ID/商家ID"
            v-model="searchValue.idSearch"
            @keyup.enter.native="getList"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="5">
          <span class="searchText">名称</span>
          <el-input
            class="searchInput"
            placeholder="请输入用户昵称/商品名称/商家名称"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.textSearch"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="5">
          <span class="searchText">手机号</span>
          <el-input
            class="searchInput"
            placeholder="请输入手机号"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.mobile"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="5">
          <el-form inline>
            <bm-areaSelect v-model="searchValue.districtId"></bm-areaSelect>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="5">
          <span class="searchText">券码来源</span>
          <el-select placeholder="请选择券码来源" v-model="searchValue.checkCodeSource">
            <el-option v-for="(i, n) in checkCodeSourceList" :label="i.name" :key="n" :value="i.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <span class="searchText">秒杀专用</span>
          <el-select placeholder="请选择秒杀专用" v-model="searchValue.secKill">
            <el-option v-for="(i, n) in secKillList" :label="i.name" :key="n" :value="i.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <span class="searchText">是否预定</span>
          <el-select placeholder="请选择是否预定" v-model="searchValue.reservation">
            <el-option v-for="(i, n) in reservationList" :label="i.name" :key="n" :value="i.value"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query">
        <el-col :span="5">
          <span class="searchText">订单状态</span>
          <el-select placeholder="请选择订单状态" v-model="searchValue.orderShowStatus">
            <el-option
              v-for="(item, index) in stateOptions"
              :label="item.label"
              :key="index"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <span class="searchText">核销码</span>
          <el-input
            class="searchInput"
            placeholder="请输入核销码"
            @keyup.enter.native="getList"
            v-model.trim="searchValue.orderCheckCode"
            clearable
          >
          </el-input>
        </el-col>
        <el-col :span="12">
          <span class="searchText">订单创建时间</span>
          <el-date-picker
            v-model="orderTime"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" class="query" style="text-align: right;">
        <el-col :span="24">
          <el-button type="info" @click="resetSearch()">重置</el-button>
          <el-button type="primary" @click="getList()"> 搜索</el-button></el-col
        >
      </el-row>
    </el-card>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>订单列表</span>

        <el-button type="success" @click="downloadExcel()" style="float: right"> 导出Excel</el-button>
        <el-button type="success" @click="handleBatchRefund()" style="float: right; margin-right: 10px">
          批量发起退款</el-button
        >
      </div>
      <el-table
        :data="list"
        v-loading="loading"
        :row-key="getRowKeys"
        :expand-row-keys="expands"
        @row-click="clickRowHandle"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="checkCode">
              <div class="item">
                <div class="desc">子订单号 :</div>
                <div>
                  <div class="item" v-for="(item, index) in props.row.checkCodeList" :key="index">
                    {{ item.orderCheckCodeId }}
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="desc">核销码 :</div>
                <div>
                  <div class="item" v-for="(item, index) in props.row.checkCodeList" :key="index">
                    {{ item.checkCode }}
                  </div>
                </div>
              </div>

              <div class="item">
                <div class="desc">核销状态 :</div>
                <div v-if="props.row.orderShowStatus === 4">订单关闭，没有核销码</div>
                <div v-else>
                  <div class="item" v-for="(item, index) in props.row.checkCodeList" :key="index">
                    {{ item.checkStatus | checkCodeStatus }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="订单号"> </el-table-column>
        <el-table-column align="center" label="商品信息">
          <template slot-scope="scope">
            <div class="product-info">
              <img class="avatar" :src="scope.row.skuPic | thumbMedium" />
              <span class="name"> {{ scope.row.productName }}</span>
              <!-- </el-tooltip> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="businessName" label="商家名称"> </el-table-column>
        <el-table-column align="center" prop="memberName" label="用户昵称"> </el-table-column>

        <el-table-column align="center" label="地区">
          <template slot-scope="scope">
            {{ scope.row.areaId | formatArea }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="skuTotalNum" label="购买数量"> </el-table-column>
        <el-table-column align="center" label="商品类型">
          <template slot-scope="scope">
            {{ scope.row.productSaleType | productSaleType }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="skuPayMoney" label="支付金额"> </el-table-column>
        <el-table-column align="center" prop="status" label="订单状态">
          <template slot-scope="scope">
            {{ scope.row.orderShowStatus | formatOrderStatus }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="orderTime" label="订单创建时间">
          <template slot-scope="scope">
            {{ scope.row.orderTime | formatTime }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="notes" label="订单备注">
          <template slot-scope="scope">
            <el-tooltip effect="dark" placement="top" :content="scope.row.notes">
              <span> {{ scope.row.notes | sliceString(10) }}...</span>
            </el-tooltip>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="check(scope.row, 'check')" type="text" size="small"> 查看 </el-button>
            <el-button @click="check(scope.row)" type="text" size="small"> 退款 </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页组件 -->
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :close-on-click-modal="false" title="导出订单" :visible.sync="download.show" append-to-body width="30%">
      <span style="display: inline-block; width: 60px"> 时间</span>
      <el-date-picker
        value-format="timestamp"
        v-model="download.time"
        type="datetimerange"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>

      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 状态</span>
        <el-select placeholder="请选择订单状态" v-model="download.status">
          <el-option
            v-for="(item, index) in exportOrderStatus"
            :label="item.label"
            :key="index"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
      <div style="margin-top: 10px">
        <span style="display: inline-block; width: 60px"> 商品ID</span>
        <el-input placeholder="输入商品ID" style="width: 300px" v-model="download.productId"> </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="download.show = false">取 消</el-button>
        <el-button type="primary" @click="confirmDownload">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量退款 -->

    <el-dialog :close-on-click-modal="false" :title="batchRefund.title" :visible.sync="batchRefund.show" append-to-body>
      <div v-show="batchRefund.step === 1">
        <div style="margin: 10px 0">
          <span style="margin-right: 24px">订单状态</span>

          <el-select placeholder="请选择订单状态" v-model="orderShowStatus">
            <el-option label="未核销" value="1">未核销</el-option>
            <el-option label="已过期" value="3">已过期</el-option>
          </el-select>
          <span style="margin-left: 10px; margin-right: 24px">订单创建时间</span>
          <el-date-picker
            v-model="data"
            type="datetimerange"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleSearch()"
          >
          </el-date-picker>
        </div>

        <el-input
          class="searchInput"
          @input="handleSearch"
          placeholder="请输入商品ID"
          v-model.trim="batchRefund.id"
          clearable
        >
          <template slot="prepend">商品ID</template>
          <el-button slot="append" @click="handleSearch()" icon="el-icon-search"></el-button>
        </el-input>

        <div class="vendor" v-show="batchRefund.businessName">
          <div class="item"><span class="name"> 商家:</span> {{ batchRefund.businessName }}</div>
          <div class="item"><span class="name">商品名称: </span>{{ batchRefund.productName }}</div>
          <!-- <div class="item"><span class="name"> 商品图片: </span> <img :src="batchRefund.skuPic" /></div> -->
        </div>
      </div>
      <div v-show="batchRefund.step === 2">
        <el-table :data="batchRefund.list" height="500" ref="multipleTable" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column align="center" prop="id" label="订单号"> </el-table-column>
          <el-table-column align="center" label="商品信息">
            <template slot-scope="scope">
              <div class="product-info">
                <img class="avatar" :src="scope.row.skuPic | thumbMedium" />
                <span class="name"> {{ scope.row.skuName }}</span>
                <!-- </el-tooltip> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="businessName" label="商家名称"> </el-table-column>
          <el-table-column align="center" prop="memberName" label="用户昵称"> </el-table-column>

          <!-- <el-table-column align="center" label="地区">
            <template slot-scope="scope">
              {{ scope.row.areaId | formatArea }}
            </template>
          </el-table-column> -->

          <el-table-column align="center" prop="skuTotalNum" label="购买数量"> </el-table-column>
          <el-table-column align="center" prop="skuPayMoney" label="支付金额"> </el-table-column>
          <el-table-column align="center" prop="status" label="订单状态">
            <template slot-scope="scope">
              {{ scope.row.orderShowStatus | formatOrderStatus }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="orderTime" label="订单创建时间">
            <template slot-scope="scope">
              {{ scope.row.orderTime | formatTime }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          :disabled="batchRefund.businessName === ''"
          v-show="batchRefund.step === 1"
          type="primary"
          @click="confirmNext"
          >确定是这个商家并下一步</el-button
        >
        <el-select
          style="margin-right: 20px"
          v-show="batchRefund.step === 2"
          v-model="batchRefund.refundReasonId"
          placeholder="请选择"
        >
          <el-option v-for="item in batchRefund.rejectReasonList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <el-button
          :disabled="batchRefund.orderIdList.length === 0"
          v-show="batchRefund.step === 2"
          type="primary"
          @click="confirmRefund"
          >发起退款</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
// 引入分页的混入
import { pagination } from '@/mixin/pagination'

export default {
  mixins: [pagination],
  data() {
    return {
      getRowKeys(row) {
        return row.id
      },
      // 要展开的行，数值的元素是row的key值
      expands: [],
      orderShowStatus: '1',
      data: [],
      exportOrderStatus: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '不可核销'
        },
        {
          value: '1',
          label: '未核销'
        },
        {
          value: '2',
          label: '已核销'
        },
        {
          value: '3',
          label: '已过期'
        }
      ],
      stateOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '待支付'
        },
        {
          value: '1',
          label: '待核销'
        },
        {
          value: '2',
          label: '已核销'
        },
        {
          value: '3',
          label: '已过期'
        },
        {
          value: '4',
          label: '已关闭'
        },
        {
          value: '11',
          label: '退款中'
        },
        {
          value: '12',
          label: '已退款'
        },
        {
          value: '21',
          label: '已评价'
        }
      ],
      download: {
        status: '',
        show: false,
        url: '',
        time: [],
        productId: ''
      },
      // 搜索状态栏的时间，因为是个数组，所以不能直接赋值给search
      orderTime: [],
      searchValue: {
        idSearch: '',
        textSearch: '',
        checkCodeSource: '',
        secKill: '',
        reservation: '',
        id: '',
        productName: '',
        memberId: '',
        orderCheckCode: '',
        businessName: '',
        memberName: '',
        orderShowStatus: '',
        orderTimeEnd: '',
        orderTimeStart: '',
        mobile: '',
        buyTypeList: '0,1,3' //0: 普通； 1： 霸王餐， 3：限时特卖
      },
      loading: false,
      // 表格的数据
      list: [],
      batchRefund: {
        rejectReasonList: [],
        show: false,
        title: '选择商品',
        size: 100000,
        id: '',
        step: 1,
        list: [],
        total: 0,
        // skuPic: '',
        productName: '',
        businessName: '',
        orderIdList: [],
        refundReason: '',
        refundReasonId: ''
      },
      checkCodeSourceList: [
        { name: '全部', value: '' },
        { name: '无', value: 0 },
        { name: '平台', value: 1 },
        { name: '核销码池', value: 2 },
        { name: '第三方', value: 3 }
      ],
      secKillList: [
        { name: '全部', value: '' },
        { name: '否', value: 0 },
        { name: '是', value: 1 }
      ],
      reservationList: [
        { name: '全部', value: '' },
        { name: '不需要预定', value: 0 },
        { name: '需要预定', value: 1 }
      ]
    }
  },
  filters: {
    productSaleType(val) {
      // 1-普通商品、2-导码商品、 3-无码商品、 4-限时商品
      switch (val) {
        case 1:
          return '普通商品'
          break
        case 2:
          return '导码商品'
          break
        case 3:
          return '无码商品'
          break
        case 4:
          return '限时商品'
          break
        default:
          return '限时商品'
          break
      }
    },
    checkCodeStatus(val) {
      switch (val) {
        case 0:
          return '不可核销'
          break
        case 1:
          return '未核销'
          break
        case 2:
          return '已核销'
          break
        case 3:
          return '已过期'
          break
        case 11:
          return '退款中'
          break
        case 12:
          return '已退款'
          break

        default:
          return ''
          break
      }
    }
  },
  created() {
    this.getRefundReasonList()
  },
  methods: {
    // =================================================批量退款的==========================
    // 选择订单
    handleSelectionChange(val) {
      this.batchRefund.orderIdList = val.map(item => {
        return item.id
      })
    },
    // 确认退款
    confirmRefund() {
      this.batchRefund.rejectReasonList.forEach(item => {
        if (item.id == this.batchRefund.refundReasonId) {
          this.batchRefund.refundReason = item.name
        }
      })
      this.batchRefund.refundReason
      this.$http
        .post('/boom-center-order-service/sysAdmin/refund/apply/batch', {
          orderIdList: this.batchRefund.orderIdList,
          refundReason: this.batchRefund.refundReason,
          refundReasonId: this.batchRefund.refundReasonId
        })
        .then(res => {
          this.batchRefund.show = false
          this.getList()
          this.$message.success('操作成功,请到申请退款列表查看')
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
    },
    // 点击按钮，出现弹窗
    handleBatchRefund() {
      // this.batchRefund.skuPic = ''
      this.batchRefund.productName = ''
      this.batchRefund.businessName = ''
      this.batchRefund.id = ''
      this.batchRefund.step = 1
      this.batchRefund.show = true
      this.batchRefund.title = '选择商品'
    },

    clickRowHandle(row, column, event) {
      if (this.expands.includes(row.id)) {
        this.expands = this.expands.filter(val => val !== row.id)
      } else {
        this.expands.push(row.id)
      }
    },

    // 确认商家并下一步
    confirmNext() {
      this.batchRefund.id = ''
      this.batchRefund.step = 2
      this.batchRefund.title = '选择订单'
    },

    // 弹框搜索订单
    handleSearch() {
      if (this.batchRefund.id === '') {
        this.$message.error('请输入商品ID')
        this.batchRefund.businessName = ''
        return
      }
      let config = { ['Bm-Area-Id']: this.searchValue.districtId }
      let obj = {
        memberId: '',
        memberName: '',
        orderCheckCode: '',
        businessName: '',
        orderShowStatus: this.orderShowStatus,
        productName: '',
        mobile: '',
        districtId: '',
        orderTimeEnd: this.orderTime[1],
        orderTimeStart: this.orderTime[0],
        buyTypeList: '0,1,3'
      }

      if (this.data) {
        obj.orderTimeStart = this.data[0]
        obj.orderTimeEnd = this.data[1]
      } else {
        obj.orderTimeStart = ''
        obj.orderTimeEnd = ''
      }
      let payload = {
        size: 10000,
        id: this.batchRefund.id,
        ...obj
      }
      this.$api.order
        .order('orderList', {
          params: payload,
          headers: config
        })
        .then(res => {
          this.batchRefund.list = res.list
          this.batchRefund.total = res.total
          if (this.batchRefund.list.length === 0) {
            this.$message.error(
              `没有找到${this.orderShowStatus == 1 ? '未核销' : '已过期'}状态的订单，请检查商品ID或者状态`
            )

            this.batchRefund.businessName = ''
          } else {
            // this.batchRefund.skuPic = this.batchRefund.list[0].skuPic
            this.batchRefund.productName = this.batchRefund.list[0].productName
            this.batchRefund.businessName = this.batchRefund.list[0].businessName
          }
        })
        .catch(err => {
          this.$message.error('获取订单表格错误')
        })
    },

    ///退款原因列表
    getRefundReasonList() {
      this.$api.base
        .base('refundReasonList', {
          params: {
            page: 1,
            size: 100
          }
        })
        .then(res => {
          if (res.list.length) {
            this.batchRefund.rejectReasonList = res.list
            this.batchRefund.refundReasonId = res.list[0].id
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },

    // 点击重置按钮，先清空再搜索
    resetSearch() {
      this.orderTime = []
      this.searchValue = {
        id: '',
        memberId: '',
        memberName: '',
        orderCheckCode: '',
        businessName: '',
        orderShowStatus: '',
        productName: '',
        mobile: '',
        districtId: '',
        orderTimeEnd: this.$store.getters['global/todayInt'],
        orderTimeStart: this.$store.getters['global/day7Int'],
        buyTypeList: '0,1,3'
      }
      this.getList(false, true)
    },
    // 搜索
    getList(val, reset) {
      this.loading = true
      let config =
        reset === true
          ? {}
          : {
              ['Bm-Area-Id']: this.searchValue.districtId
            }

      if (this.orderTime) {
        this.searchValue.orderTimeStart = this.orderTime[0]
        this.searchValue.orderTimeEnd = this.orderTime[1]
      } else {
        this.searchValue.orderTimeStart = ''
        this.searchValue.orderTimeEnd = ''
      }

      this.searchValue.size = this.pageSize

      // 判断是否是分页还是普通搜索
      this.searchValue.page = val === true ? this.currentPage : 1

      let { districtId, ...payload } = this.searchValue
      if (payload.checkCodeSource === '') {
        payload.checkCodeSource = null
      }
      if (payload.secKill === '') {
        payload.secKill = null
      }
      if (payload.reservation === '') {
        payload.reservation = null
      }
      this.loading = true
      this.$http
        .post('boom-center-search-service/sysAdmin/order/page/v1', payload, {
          headers: config
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error('获取订单表格错误')
        })
        .finally(() => {
          this.loading = false
        })
    },

    // 查看订单
    check(row, check) {
      let type = ''
      if (check === 'check') {
        type = 'refundDetail'
      }
      this.$router.push({
        path: '/order/orderParticulars',
        query: {
          id: row.id,
          memberId: row.memberId,
          type: type
        }
      })
    },
    //==========================================================================下载Excel表格
    confirmDownload() {
      const loading = this.$loading({
        lock: true,
        text: '正在下载，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$http
        .get(this.download.url, {
          params: {
            startTime: this.download.time ? this.download.time[0] : '',
            endTime: this.download.time ? this.download.time[1] : '',
            status: this.download.status,
            productId: this.download.productId
          }
        })
        .then(res => {
          this.$message.success('下载中，请到导出记录页面查看')
          this.download.show = false
        })
        .catch(err => {
          this.$message.error(err.msg)
        })
        .finally(() => {
          loading.close()
        })
    },
    downloadExcel() {
      this.download = {
        status: '',
        show: true,
        url: '/boom-center-statistics-service/sysAdmin/exportExl/allStatusOrderExport',
        time: [],
        productId: ''
      }
    }
  }
}
</script>

<style lang="less" scoped>
.product-info {
  display: flex;
  align-items: center;
  .name {
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    vertical-align: middle;
    object-fit: cover;
    border-radius: 5px;
  }
}

.query {
  margin-top: 20px;
}
.checkCode {
  display: flex;
  justify-content: center;
  .item {
    display: flex;
    align-items: center;
    .desc {
      margin: 0 20px;
    }
    .item {
      margin: 5px;
    }
  }
}
.vendor {
  .item {
    display: flex;
    align-items: center;
    margin: 30px;
    .name {
      display: inline-block;
      width: 100px;
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 10px;
    }
  }
}
</style>
